<template>
  <div class="bbr-exercises">
    <main-app-bar>
      <template v-slot:title>
        Exercises
      </template>
      <template v-slot:actions>
        <div class="app-bar--bottom-actions nav-pills">
          <v-btn :to="{ name: 'active.exercises' }" depressed replace>
            Active Exercises
          </v-btn>

          <v-btn :to="{ name: 'draft.exercises' }" depressed replace>
            Draft Exercises
          </v-btn>

          <v-btn :to="{ name: 'import.exercises' }" depressed replace>
            Import Exercises
          </v-btn>
        </div>
        <v-btn
          class="bg-primary-gradient ml-auto"
          color="primary"
          rounded
          :to="{ name: 'new.exercise' }"
        >
          <v-icon class="mr-1" small>
            {{ icons.add }}
          </v-icon>
          Add New Exercise
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-3 px-10 mb-6">
      <v-row>
        <v-col v-show="$route.name !== 'import.exercises'" cols="12">
          <div class="form-container d-flex align-center justify-end">
            <div class="search-form">
              <v-text-field
                v-model="searchKey"
                flat
                hide-details
                solo
                rounded
                clearable
                label="Search Exercises"
                style="width: 400px"
                @input="searchChange"
                data-testid="exercise-search-input"
              />
            </div>
            <v-btn color="primary" dark text @click="openFilter = true">
              <v-icon>{{ icons.filter }}</v-icon>
              <span class="ml-3"> Filter </span>
            </v-btn>
          </div>
        </v-col>
        <v-col v-if="openFilter" cols="12" class="mb-5">
          <v-card outlined>
            <v-card-text>
              <div>
                <v-row justify="space-between">
                  <v-col md="4">
                    <search-trainer-input
                      v-model="filter.trainer"
                      placeholder="Exercise Trainer"
                      hint="Search and select multiple trainers"
                      persistent-hint
                      clearable
                      outlined
                      multiple
                      flat
                    />
                  </v-col>
                  <v-col md="4">
                    <v-select
                      v-model="filter.inTutorialLibrary"
                      :items="[
                        { value: true, text: 'In tutorial library' },
                        { value: false, text: 'Not in tutorial library' },
                        { value: null, text: 'All' },
                      ]"
                      label="Tutorial Library"
                      outlined
                      flat
                    />
                  </v-col>
                  <v-col md="4">
                    <v-select
                      v-model="filter.hasCode"
                      :items="[
                        { value: true, text: 'Has exercise code' },
                        { value: false, text: 'Has no exercise code' },
                        { value: null, text: 'All' },
                      ]"
                      label="Exercise Code"
                      outlined
                      flat
                    />
                  </v-col>
                </v-row>
              </div>
            </v-card-text>

            <hr />

            <v-card-actions>
              <v-spacer />
              <v-btn text @click="openFilter = false">
                Cancel
              </v-btn>

              <v-btn color="warning" text @click="resetFilters">
                Reset
              </v-btn>

              <v-btn color="primary" text @click="applyFilters">
                Apply Filters
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12">
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import MainAppBar from '@/layouts/shared/MainAppBar'
import SearchTrainerInput from '@/components/elements/trainers/TrainerSearchInput'
import Event from '@/services/eventBus'
import { debounce, isBoolean } from 'lodash'
import { mdiPlus, mdiTune } from '@mdi/js'

export default {
  name: 'ExercisesPage',

  components: {
    MainAppBar,
    SearchTrainerInput,
  },

  data() {
    return {
      page: 1,
      searchKey: '',
      addNew: false,
      loading: false,
      icons: {
        add: mdiPlus,
        filter: mdiTune,
      },
      openFilter: false,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.exercises.filter,
      exercises: (state) => state.exercises.list,
      listMeta: (state) => state.exercises.listMeta,
    }),
    hasFilters() {
      return (
        !!this.filter.trainer.length ||
        isBoolean(this.filter.inTutorialLibrary) ||
        isBoolean(this.filter.hasCode)
      )
    },
  },

  created() {
    this.searchKey = this.filter.search

    this.ifHasSearch()

    Event.$on('new-exercise-added', (option) => {
      this.fetchExercises()
    })
  },

  methods: {
    ...mapActions({
      getExercises: 'exercises/getExercises',
    }),

    ...mapMutations({
      clearList: 'exercises/clearList',
      setFilter: 'exercises/setFilter',
      resetFilter: 'exercises/resetFilter',
    }),

    ifHasSearch() {
      if (this.searchKey) {
        this.fetchExercises()
      }
    },
    searchChange(key) {
      this.setFilter({
        search: this.searchKey,
        completed: this.$route.meta.completed,
      })

      this.searchExercises()
    },

    searchExercises: debounce(function() {
      this.clearList()
      this.fetchExercises()
    }, 600),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchExercises(this.listMeta.current_page + 1)
      }
    },

    async fetchExercises(page = 1) {
      if (this.loading) return

      this.loading = true
      let search = this.searchKey
      let params = { page, search, ...this.filter }

      await this.getExercises(params)

      this.loading = false
    },
    applyFilters() {
      if (!this.hasFilters) return

      this.searchExercises()
    },

    resetFilters() {
      this.resetFilter()
      this.searchExercises()
    },
  },
  watch: {
    $route(to, from) {
      this.searchKey = null
      this.ifHasSearch()
    },
  },
}
</script>
