<template>
  <v-autocomplete
    v-bind="$attrs"
    v-model="trainer"
    item-value="id"
    item-text="name"
    :items="trainers"
    :search-input.sync="search"
    :hide-no-data="hideNoData"
    @change="selectedTrainer"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-text="data.item.name" />
        <v-list-item-subtitle class="text-no-wrap" style="width:20px">
          {{ data.item.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { find, isEmpty, isArray, debounce } from 'lodash'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TrainerSearchInput',

  props: {
    value: {
      type: [Number, Object, Array],
    },

    defaultValue: {
      type: [Object, Array],
    },
  },

  data() {
    return {
      search: null,
      trainer: null,
      trainers: [],
    }
  },

  computed: {
    ...mapGetters({
      isLoading: 'trainers/isLoading',
    }),

    hasSearch() {
      return !isEmpty(this.search)
    },

    hasTrainers() {
      return !isEmpty(this.trainers)
    },

    hasInputValue() {
      return !isEmpty(this.trainer)
    },

    hideNoData() {
      return !this.hasSearch || this.hasTrainers
    },
  },

  created() {
    this.setDefaultValue()
    this.setDefaultSelectedValue()
  },

  methods: {
    ...mapActions({
      getTrainers: 'trainers/getTrainers',
    }),

    setDefaultValue() {
      if (isEmpty(this.defaultValue)) return

      let value = isArray(this.defaultValue)
        ? [...this.plans, ...this.defaultValue]
        : [this.defaultValue]

      this.trainers = value
    },

    fetchTrainers: debounce(async function() {
      let trainers = await this.getTrainers({
        search: this.search,
        page: 1,
        limit: 100,
        toState: false,
      })

      this.trainers = trainers.data
    }, 800),

    selectedTrainer(id) {
      let trainer = find(this.trainers, { id })

      this.search = null
      this.$emit('change', trainer)
      this.$emit('input', id)
    },

    setDefaultSelectedValue() {
      this.trainer = this.value
    },
  },

  watch: {
    search(key) {
      if (key) {
        this.fetchTrainers()
      }
    },

    value(value) {
      this.setDefaultSelectedValue()
    },

    defaultValue(values) {
      this.setDefaultValue()
    },
  },
}
</script>
